@import 'src/theme';

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - #{$topBarSize} - #{$footerSize});
    background-color: $base;

    &.deep-base {
        background-color: $deepBase;
    }

    @media (max-width: $mobileWidth) {
        min-height: unset;
    }
}

.page-header {
    position: relative;
    width: 100%;
    max-width: $siteWidth;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 80px;
    margin: 0 auto;

    @media (max-width: $mobileWidth) {
        padding: 0 $spacing4;
    }
}

.page-content {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 80px $spacing4;

    @media (max-width: $mobileWidth) {
        padding: 0;
    }
}

.page-footer {
    width: 100%;
    min-height: $footerSize;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
}

.page-footer-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $grey2;
        font-size: 14px;
        font-weight: bold;
        padding: 0 16px;
        text-decoration: none;

        @media (max-width: $mobileWidth) {
            padding: 12px 24px;
        }
    }

    @media (max-width: $mobileWidth) {
        flex-direction: column;
        padding: 12px 0;
    }
}
