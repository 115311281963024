@import 'src/theme';



.period-wrapper {
    width: min-content;
    margin-bottom: 50px;

    .period {
        padding: 8px;
        font-size: 12px;
    }

    .period:hover {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }


}

.period-wrapper:not(:hover) {
    >.clicked {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }
}


.account-button {

    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $grey3;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: $white;
    border-radius: $borderRadius;
    box-shadow: none;

    > .qubit-balance {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        padding: 0 10px;

        > .symbol {
            width: 17px;
            height: 17px;
            margin-right: 6px;
        }

        @media (max-width: $mobileWidth) {
            display: none;
        }

        &.connected {
            display: flex;
        }
    }

    > .divider {
        width: 1px;
        height: 16px;
        background-color: $grey1;

        @media (max-width: $mobileWidth) {
            display: none;
        }
    }

    > .wallet-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        padding: 0 12px 0 10px;
        white-space: nowrap;

        > .pending-loader {
            margin-left: $spacing1;
            -webkit-animation: 2.5s linear infinite loaderRotate;
            -moz-animation: 2.5s linear infinite loaderRotate;
            animation: 2.5s linear infinite loaderRotate;
        }

        @media (max-width: $mobileWidth) {
            display: none;
        }

        &.connect-wallet {
            display: flex;
        }
    }
}

@-webkit-keyframes loaderRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes loaderRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes loaderRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
