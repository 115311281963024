@import 'src/theme';

.chart-group {
    width: 100%;
    min-height: 251px;
    min-width: 300px;
    padding: 8px 8px 8px 8px;

    .chart-group-relative {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .chart-wrapper {
        padding-right: 8px;
    }
}
