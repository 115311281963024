@import 'src/theme';

.market-detail-group {
    width: 100%;
    min-width: 300px;

    .block {
        padding: 25px 24px;
        width: 100%;
        height: 63px;
        font-size: 14px;
        color: $grey2;
        justify-content: space-between;

        > .block-content {
            font-size: 13px;
            color: $grey3;
            font-weight: bold;
        }

    }

}
