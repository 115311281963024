@import 'src/theme';

.local-loader {
    width: 100%;
    height: 40vh;

    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: 800ms linear infinite localLoaderScale;
    -moz-animation: 800ms linear infinite localLoaderScale;
    animation: 800ms linear infinite localLoaderScale;

    & > * {
        width: 72px;
    }
}

@-webkit-keyframes localLoaderScale {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@-moz-keyframes localLoaderScale {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes localLoaderScale {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
