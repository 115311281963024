@import 'src/theme';

.info-detail-portfolio-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 80px 28px;
  background-color: $white;

  @media (max-width: $mobileWidth) {
    padding: 0;
  }
}


.market-title {
  width: 100%;
  max-width: $midWidth;
  font-size: 14px;
  white-space: pre;

  @media (max-width: $mobileWidth) {
    padding: 10px;
    background-color: $base;
    border-bottom: 1px solid $grey2;
  }

  .back-icon {
    margin: 10px;
  }

  .market-title-underlined {
    width: 100%;
    font-size: 24px;
    border-bottom: 1px solid $grey3;
    padding: 0 0 12px 0px;
    margin: 31px 0;
    font-weight: bold;

    @media (max-width: $mobileWidth) {
      border-bottom: 0;
      padding: 0;
      margin: 0;
    }
  }

  .coin-icon {
    width: 45px;
    height: 45px;
    margin-right: 14px;

    @media (max-width: $mobileWidth) {
      margin-right: 8px;
    }
  }

  .dropdown-icon {
    padding: 6px;
    position: absolute;
    right: 0;
  }

  .dropdown-icon:hover {
    background-color: white;
  }

  .market-item {
    font-size: 14px;
    font-weight: bold;
  }
}

.market-portfolio {
  max-width: $midWidth;
  width: 100%;
  display: grid;
  justify-content: flex-start;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
            "chart supply"
            "chart borrow";
  column-gap: 24px;
  row-gap: 48px;
  color: $grey3;

  @media (max-width: $mobileWidth) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: "chart" "supply" "borrow" ;
    row-gap: 0px;
    border-bottom: 1px solid $grey1;
  }

  > .chart{
    grid-area: chart;
    position: relative;
    min-width: 398px;

    @media (max-width: $mobileWidth) {
      padding: 16px;
      border-bottom: 1px solid $grey1;
    }
  }

  >.supply {
    grid-area: supply;
  }

  >.borrow {
    grid-area: borrow;
  }

  .apy-wrapper {
    width: 100%;
    display: grid;
    justify-content: flex-end;
    grid-template-rows: 1fr 0.5fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
              "apy apy apy"
              "net reward total";
    font-size: 18px;
    font-weight: bold;
    column-gap: 9px;
    text-align: right;

    @media (max-width: $mobileWidth) {
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr;
      grid-template-areas: "apy" "net" "reward" "total" ;
      row-gap: 18px;
      padding: 16px 16px 30px 16px;
    }

    >.apy-title {
      grid-area: apy;

      @media (max-width: $mobileWidth) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      >.apy-label {
        font-size: 18px;
        font-weight: bold;

        @media (max-width: $mobileWidth) {
          font-size: 24px;
        }
      }

      >.apy-number {
        font-size: 36px;
        font-weight: bold;

        &.supply {
          grid-area: supply;
          color: $blue2;
        }

        &.borrow {
          grid-area: borrow;
        }

      }
    }

    >.apy-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      place-self: center;

      @media (max-width: $mobileWidth) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $grey1;


      }



      >.label-underlined {
        font-size: 14px;
        text-align: right;
        border-bottom: 1px solid $grey2;
        width: 100%;
        padding: 5px 0;
        margin: 5px 0;
        font-weight: normal;

        @media (max-width: $mobileWidth) {
          border-bottom: 0;
          width: auto;
        }
      }

      &.net {
        grid-area: net;
      }
      &.reward {
        grid-area: reward;
      }
      &.total {
        grid-area: total;
      }
    }
  }

  > img {
    width: 45px;
    height: 45px;
    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.25));

    @media (max-width: $mobileWidth) {
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }
  }
}