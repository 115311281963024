@import 'src/theme';

.info-group-wrapper {
  max-width: $midWidth;
  align-self: center;
  margin: 56px 0 168px;
  width: 100%;

  .info-group-label {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;

    @media (max-width: $mobileWidth) {
      margin-left: 17px;
      font-weight: 900;
    }
  }
}