@import 'src/theme';


.info-detail-group {
  max-width: $midWidth;
  min-width: $midWidth;
  align-self: center;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
          "reward market"
          "average market";
  column-gap: 10px;
  margin-top: 24px;
  margin-bottom: 35px;
  color: $grey3;

  @media (max-width: $mobileWidth) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: "reward" "average" "market";
    margin-top: 32px;
    min-width: 0;
  }

  .info-detail-group-wrapper {
    width: 100%;
    min-width: $mobileMinWidth;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: $mobileWidth) {
      padding: 0 17px;
    }

    &.reward {
      grid-area: reward;
    }

    &.average {
      grid-area: average;
    }

    &.market {
      grid-area: market;
    }


  }
}

  .group-label {
    font-weight: bold;
    font-size: 18px;
    margin: 31px 0 16px 8px;

    @media (max-width: $mobileWidth) {
      margin: 18px 0 16px;
    }

}
