@import 'src/theme';

.information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 $spacing1;
}

.information-text {
    padding: $spacing3;
    color: $white;
    font-size: 13px;
}


