@import 'src/theme';

.group-divider {
  width: 100%;
  height: 1px;
  background-color: $grey1;
}

.group-col-divider {
  height: 63px;
  width: 1px;
  background-color: $grey1;
}