@import 'src/theme';

.card {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $borderRadius;
    border: solid 1px #e4e4e4;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    flex: 1;
}

.card-divider {
    min-height: 1px;
    max-height: 1px;
    flex: 1;
    margin: 0 $spacing3;
    background-color: #e4e4e4;

    &.fill {
        margin: 0;
    }
}

.card-icon {
    height: 48px;
    width: fit-content;
    border-radius: 25px;

    > img {
        width: 100%;
        height: 100%;
    }
}
