@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import 'src/theme';

html {
    background-color: $white;
}

body {
    margin: 0;
    font-family: 'Roboto', 'sans-serif';
    font-size: 16px;
    color: $grey3;
    line-height: 1.4;
    letter-spacing: 0.02em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}

div, span, input {
    box-sizing: border-box;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.clickable {
    cursor: pointer;
}

.suspense {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.number {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

.same-line {
    display: flex;
    align-items: center;

    &.space {
        justify-content: space-between;
    }
}

.relative {
    position: relative;
}
