@import 'src/theme';

.legend-wrapper {
  position: absolute;
  top: 8px;
  right: 8px;
  color: $grey3;
  font-size: 11px;

  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $grey3;
    margin-right: 4px;

    &.supply {
      background-color: $blue2;
    }
  }

}

