@import 'src/theme';

.info-portfolio-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 80px 50px;
    background-color: $qubitBase;
    min-width: $mobileMinWidth;

    @media (max-width: $mobileWidth) {
        padding: 20px 17px 30px;
    }
}

.info-portfolio {
    max-width: $midWidth;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
            "totalSupply totalBorrow"
            "supplyTopMarket borrowTopMarket";
    column-gap: 30px;
    color: $grey3;
    margin-bottom: 50px;

    @media (max-width: $mobileWidth) {
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr;
        grid-template-areas: "totalSupply" "supplyTopMarket" "totalBorrow" "borrowTopMarket";
        row-gap: 19px;
        margin-bottom: 30px;
    }

    > .total {

        &.supply {
            grid-area: totalSupply;
        }

        &.borrow {
            grid-area: totalBorrow;
        }

        .total-number {
            width: 100%;
            font-weight: bold;
            font-size: 32px;
            margin-top: 10px;

            @media (max-width: $mobileWidth) {
                margin-top: 8px;
            }

            .plus {
                color: $rise;
            }

            .minus {
                color: $alert;
            }
        }
    }

    > .top-markets {
        &.supply {
            grid-area: supplyTopMarket;
        }

        &.borrow {
            grid-area: borrowTopMarket;

            @media (max-width: $mobileWidth) {
                margin-bottom: 28px;
            }
        }


        @media (max-width: $mobileWidth) {
            margin-bottom: 26px;
        }

        .top-market-label {
            font-size: 13px;
            text-align: left;

            &.title {
                margin-bottom: 14px;

                @media (max-width: $mobileWidth) {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        > .bar-wrapper {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            grid-area: bar;
            margin-bottom: 18px;
            z-index: 2;

            > .bar {
                height: 8px;
                position: absolute;
                top: 22px;
                left: 0;
                right: 0;
                background-color: $grey3;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }

            > .backdrop {
                height: 8px;
                position: absolute;
                top: 22px;
                left: 0;
                right: 0;
                background-color: $white;
            }

            > .top-series {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                z-index: 3;

                > .top-series-value {
                    font-size: 14px;
                    font-weight: bold;
                    text-align: right;
                }
            }
        }

    }
}

.q-score {
    max-width: $midWidth;
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "totalQscore avgQscore avgBoost";
    display: grid;
    justify-content: center;
    column-gap: 22px;

    @media (max-width: $mobileWidth) {
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr;
        grid-template-areas: "totalQscore" "avgQscore" "avgBoost";
        row-gap: 19px;
    }

    > .q-score-item {
        width: 100%;

        .q-score-item-value {
            font-weight: bold;
            font-size: 18px;

            &.icon {
                width: 18px;
                height: 18px;
            }
        }

        .info-icon {
            position: absolute;
            right: 0;
            top: 15px;
        }

        .q-score-item-rocket {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 4px;
            font-weight: bold;
            font-size: 18px;

            > img {
                width: 18px;
                height: 18px;
                margin-right: 4px;
            }
        }

        &.totalQscore {
            grid-area: totalQscore;
        }

        &.avgQscore {
            grid-area: avgQscore;
        }

        &.avgBoost {
            grid-area: avgBoost;
        }
    }
}

// text

.info-label-underlined {
    width: 100%;
    font-size: 18px;
    border-bottom: 2px solid $grey3;
    padding: 11px 0px;
    margin-bottom: 8px;
    font-weight: 800;
}

.info-mobile-label {
    font-size: 0px;

    @media (max-width: $mobileWidth) {
        width: 100%;
        font-size: 24px;
        text-align: left;
        font-weight: 900;
        margin-bottom: 16px;
    }
}


// locker animate

#e3v7DTK4yeC3_tr {
    -webkit-animation: locker_roate 8000ms linear infinite normal forwards;
    -moz-animation: locker_roate 8000ms linear infinite normal forwards;
    animation: locker_roate 8000ms linear infinite normal forwards;
}

@keyframes locker_roate {
    0% {
        transform: translate(13.500005px, 11.500000px) rotate(0deg);
        animation-timing-function: cubic-bezier(0.250000, 1, 0.250000, 1)
    }
    37.500000% {
        transform: translate(13.500005px, 11.500000px) rotate(359.780769deg)
    }
    100% {
        transform: translate(13.500005px, 11.500000px) rotate(359.780769deg)
    }
}

/* Firefox */
@-moz-keyframes locker_roate {
    0% {
        transform: translate(13.500005px, 11.500000px) rotate(0deg);
        animation-timing-function: cubic-bezier(0.250000, 1, 0.250000, 1)
    }
    37.500000% {
        transform: translate(13.500005px, 11.500000px) rotate(359.780769deg)
    }
    100% {
        transform: translate(13.500005px, 11.500000px) rotate(359.780769deg)
    }
}

/* Safari and Chrome */
@-webkit-keyframes locker_roate {
    0% {
        transform: translate(13.500005px, 11.500000px) rotate(0deg);
        animation-timing-function: cubic-bezier(0.250000, 1, 0.250000, 1)
    }
    37.500000% {
        transform: translate(13.500005px, 11.500000px) rotate(359.780769deg)
    }
    100% {
        transform: translate(13.500005px, 11.500000px) rotate(359.780769deg)
    }
}

#e3v7DTK4yeC4 {
    -webkit-animation: locker_linear 8000ms linear infinite normal forwards; /* Safari and Chrome */
    -moz-animation: locker_linear 8000ms linear infinite normal forwards;    /* Firefox */
    animation: locker_linear 8000ms linear infinite normal forwards;
}

@keyframes locker_linear {
    0% {
        width: 1px;
        height: 3.378680px
    }
    25% {
        width: 1px;
        height: 3.378680px
    }
    42.500000% {
        width: 1px;
        height: 3.378680px
    }
    100% {
        width: 1px;
        height: 3.378680px
    }
}

/* Safari and Chrome */
@-webkit-keyframes locker_linear {
    0% {
        width: 1px;
        height: 3.378680px
    }
    25% {
        width: 1px;
        height: 3.378680px
    }
    42.500000% {
        width: 1px;
        height: 3.378680px
    }
    100% {
        width: 1px;
        height: 3.378680px
    }
}

/* Firefox */
@-moz-keyframes locker_linear {
    0% {
        width: 1px;
        height: 3.378680px
    }
    25% {
        width: 1px;
        height: 3.378680px
    }
    42.500000% {
        width: 1px;
        height: 3.378680px
    }
    100% {
        width: 1px;
        height: 3.378680px
    }
}
