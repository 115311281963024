$siteWidth: 1200px;
$mobileWidth: 768px;
$mobileMinWidth: 300px;
$midWidth: 800px;
$topBarSize: 88px;
$footerSize: 72px;
$borderRadius: 4px;
$presaleWidth: 800px;
$noticeHeight: 46px;

$spacing0: 2px;
$spacing1: 4px;
$spacing2: 8px;
$spacing3: 16px;
$spacing4: 24px;
$spacing5: 32px;
$spacing6: 48px;

$white: #FFF;
$black: #000;
$base: #FAFAFA;
$mint: #4ED8DE;
// qbt colors
$ui: #75858E;   // name
$deepBase: #F4F4F4;
$alert: #EF6253;
$rise: #40BA79;

$qubitBase: #FFC800;
$qubitDark: #FFA800;

$blue2: #0094FF;

$grey0: #EEEEEE;
$grey1: #E7ECF1;
$grey1_50: rgba($grey1, 0.5);
$grey2: #94A4AD;
$grey3: #33383b;
$grey3_50: rgba($grey3, 0.5);

$grey50: #f4f4f4;
$grey100: #f9fafb;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #c8c8c8;
$grey500: #999999;
$grey600: #666666;
$grey700: #616161;
