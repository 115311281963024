@import 'src/theme';

.info-card {
    background-color: $white;
    border: 1px solid $grey1;
    border-radius: 4px;
    min-width: 600px;

    @media (max-width: $mobileWidth) {
        border-radius: 0px;
        border: 0;
        border-bottom: 1px solid $grey1;
    }
}

.info-market-group {
    width: 100%;
    min-width: 600px;

    > .info-series {
        width: 100%;
        height: 64px;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 0.5fr 0.8fr 1fr 0.8fr 1fr;
        grid-template-areas: "market totalSupply supplyAPY totalBorrow borrowAPY";
        column-gap: 8px;
        padding: 0 24px;
        box-shadow: inset 0px -1px 0px $grey1;
        font-size: 13px;
        color: $grey2;

        @media (max-width: $mobileWidth) {
            padding: 0 12px;
        }

        > .info-series-item {
            width: 100%;
            white-space: nowrap;
            place-self: center;
            padding-top: 4px;
            text-align: right;

            @media (max-width: $mobileWidth) {
                font-weight: bold;
                font-size: 12px;
            }

            &.market {
                grid-area: market;
                text-align: left;
                padding-left: 4px;
            }

            &.apy {
                grid-area: apy;
                text-align: right;
            }

            &.balance {
                grid-area: balance;
                text-align: right;
            }

            &.info {
                grid-area: info;
                text-align: right;
                padding-right: 2px;
            }
        }
    }

    > .market-group-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .item-content {
            width: 100%;
            height: 64px;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 0.5fr 0.8fr 1fr 0.8fr 1fr;;
            grid-template-areas: "market totalSupply supplyAPY totalBorrow borrowAPY";
            column-gap: 8px;
            padding: 0 24px;

            @media (max-width: $mobileWidth) {
                padding: 0 12px;
            }

            > .section {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                color: $grey3;

                &.market {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    grid-area: market;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: left;
                    color: $grey3;

                    @media (max-width: $mobileWidth) {
                        font-size: 12px;
                    }

                    > img {
                        width: 34px;
                        height: 34px;
                        margin-right: 8px;

                        @media (max-width: $mobileWidth) {
                            width: 26px;
                            height: 26px;
                            margin-right: 8px;
                        }
                    }
                }

                &.supply {

                    grid-area: totalSupply;

                    > .value-apy {
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: right;

                        @media (max-width: $mobileWidth) {
                            font-size: 11px;
                        }
                    }

                    > .value-apy-max {
                        width: 100%;
                        font-size: 11.5px;
                        text-align: right;
                        color: $grey2;

                        @media (max-width: $mobileWidth) {
                            font-size: 4px;
                        }
                    }
                }

                &.sAPY {
                    grid-area: supplyAPY;

                    > .value-apy {
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: right;

                        @media (max-width: $mobileWidth) {
                            font-size: 11px;
                        }
                    }

                    > .value-apy-max {
                        width: 100%;
                        font-size: 11.5px;
                        text-align: right;
                        color: $grey2;

                        @media (max-width: $mobileWidth) {
                            font-size: 4px;
                        }
                    }
                }

                &.borrow {
                    grid-area: totalBorrow;

                    > .value-apy {
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: right;

                        @media (max-width: $mobileWidth) {
                            font-size: 11px;
                        }
                    }

                    > .value-apy-max {
                        width: 100%;
                        font-size: 11.5px;
                        text-align: right;
                        color: $grey2;

                        @media (max-width: $mobileWidth) {
                            font-size: 4px;
                        }
                    }
                }

                &.bAPY {
                    grid-area: borrowAPY;

                    > .value-apy {
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: right;

                        @media (max-width: $mobileWidth) {
                            font-size: 11px;
                        }
                    }

                    > .value-apy-max {
                        width: 100%;
                        font-size: 11.5px;
                        text-align: right;
                        color: $grey2;

                        @media (max-width: $mobileWidth) {
                            font-size: 4px;
                        }
                    }
                }

                &.balance {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    grid-area: balance;
                    font-size: 13px;
                    color: $grey2;

                    @media (max-width: $mobileWidth) {
                        font-size: 12px;
                    }
                }

                &.info {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    grid-area: info;

                    > .value-liquidity-amount {
                        width: 100%;
                        font-size: 12px;
                        font-weight: bold;
                        text-align: right;
                        color: $grey3;
                    }

                    > .value-liquidity-usd {
                        width: 100%;
                        font-size: 12px;
                        text-align: right;
                        color: $grey2;
                    }
                }
            }
        }

        > .content-divider {
            width: 100%;
            height: 1px;
            padding: 0 $spacing2;
            background-color: #e5e5e5;
        }
    }

    .content-number {
        font-weight: normal;
        font-size: 13px;
        text-align: right;


    }
    .sub-content {
        font-size: 12px;
        font-weight: bold;

        &.plus {
            color: $rise;
        }

        &.minus {
            color: $alert;
        }
    }
}
